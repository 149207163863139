<template>
	<div class="mt-4">
		<div class="headline font-weight-bold red--text mb-4">Xoá tài khoản</div>
		<div>Hệ thống sẽ xoá toàn bộ dữ liệu liên quan đến khách hàng này</div>
		<div class="mb-4">
			Nếu bạn xoá tài khoản này dữ liệu sẽ không lấy lại được !
		</div>
		<BaseButton
			@click="dialog = true"
			color="red"
			text="Tôi chắc chắn muốn xoá tài khoản này"
		/>
		<v-dialog v-model="dialog" persistent max-width="600px">
			<v-card class="pa-2">
				<v-card-title class="d-flex">
					<span class="headline font-weight-bold">Xoá khách hàng này ?</span>
				</v-card-title>
				<v-card-text>
					<div>
						Bạn có thực sự chắc chắn muốn xoá khách hàng này ?.
					</div>
					<div class="mb-6">
						Hành động này sẽ không thể quay lại.
					</div>
					<BaseInput
						v-model="confirmName"
						placeholder="Nhập dòng chữ bên dưới để xác nhận"
					/>
					<div class="mb-2">IZTeach Confirm Delete</div>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="dialog = false">
						Huỷ
					</v-btn>
					<v-btn color="red" text @click="confirmDelete">
						Xoá
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
export default {
	data() {
		return {
			dialog: false,
			confirmName: ''
		}
	},
	methods: {
		confirmDelete() {
			const input = this.$utils.clearUnicode(this.confirmName)
			this.$emit('delete', input)
			this.dialog = false
		}
	}
}
</script>
