<template>
	<div>
		<div class="d-flex justify-space-between">
			<h2>Gói đang sử dụng</h2>
			<BaseButton @click="onSave" text="Lưu" />
		</div>
		<v-select
			v-model="selectPackage"
			class="mt-4"
			style="width: 300px;"
			:items="listPackage"
			dense
			label="Gói sử dụng"
			outlined
		></v-select>
		<h2>Thời hạn sử dụng</h2>
		<base-date-picker
			:value="expiredTime"
			v-model="expiredTime"
			class="mt-4"
			style="width: 300px;"
		/>
	</div>
</template>
<script>
import BaseDatePicker from '../../components/BaseDatePicker.vue'
import {mapActions, mapGetters} from 'vuex'
import get from 'lodash-es/get'
export default {
	components: {BaseDatePicker},
	name: 'UsingPackage',
	data() {
		return {
			expiredTime: null,
			selectPackage: null,
			listPackage: this.$helpers.LIST_PACKAGES
		}
	},
	computed: {
		...mapGetters({
			vendor: 'vendor/getVendor'
		})
	},
	methods: {
		...mapActions({
			updateVendor: 'vendor/updateVendor'
		}),
		async onSave() {
			try {
				this.$message.loading('Đang cập nhật...', 10000)
				await this.updateVendor({
					id: this.$route.params.id,
					usingPackage: {
						package: this.selectPackage,
						expiredTime: this.expiredTime
					}
				})
				this.$message.success('Lưu cấu hình thành công')
			} catch (error) {
				this.$message.error(error)
			}
		}
	},
	watch: {
		vendor: {
			handler(val) {
				if (val && val.usingPackage) {
					const crExpiredTime = get(val, 'usingPackage.expiredTime')
					const crPackage = get(val, 'usingPackage.package')
					this.selectPackage = crPackage
					this.expiredTime = crExpiredTime
				}
			},
			immediate: true
		}
	}
}
</script>
