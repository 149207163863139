<template>
	<div>
		<div class="d-flex justify-space-between">
			<div class="headline font-weight-bold primary--text mb-4">
				Quản lý tài khoản admin
			</div>
			<BaseButton
				@click="() => $refs['create-admin-dialog'].openDialog()"
				icon="mdi-plus"
				text="Tạo mới"
			/>
		</div>
		<v-data-table
			class="has-border"
			disable-sort
			:headers="headers"
			:items="listAdmins"
		>
			<template v-slot:[`item.updatedAt`]="{item}">
				{{ moment(item.updatedAt).format('HH:mm - DD/MM/YYYY') }}
			</template>
			<template v-slot:[`item.actions`]="{item}">
				<div class="d-flex justify-end">
					<v-btn icon color="blue" @click="() => onLogInfo(item)">
						<v-icon small>mdi-information-outline</v-icon>
					</v-btn>
				</div>
			</template>
			<template v-slot:[`item.password`]="{item}">
				<v-text-field
					class="caption"
					:value="decipher(item.vendorId)(item.password)"
					:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
					:type="showPassword ? 'text' : 'password'"
					@click:append="showPassword = !showPassword"
				></v-text-field>
			</template>
		</v-data-table>
		<CreateAdminDialog
			:vendor="vendor"
			ref="create-admin-dialog"
			@fetchAdmins="() => onHandleFetchAdmin(vendor)"
		/>
	</div>
</template>
<script>
import moment from 'moment'
import {mapActions, mapGetters} from 'vuex'
import CreateAdminDialog from '@/modules/AdminAccount/components/CreateAdminDialog'
import {decipher} from '../../helpers/cipher.js'
export default {
	props: {
		vendor: Object
	},
	components: {
		CreateAdminDialog
	},
	data() {
		return {
			moment,
			decipher,
			listAccounts: [],
			showPassword: false,
			headers: [
				{text: 'Email', value: 'email', width: '10%'},
				{text: 'Role', value: 'roles', width: '10%', align: 'center'},
				{text: 'Mật khẩu', value: 'password', width: '20%', align: 'center'},
				{
					text: 'Cập nhật lúc',
					value: 'updatedAt',
					width: '20%',
					align: 'center'
				},
				{text: 'Thao tác', value: 'actions', width: '20%', align: 'end'}
			]
		}
	},
	computed: {
		...mapGetters({
			listAdmins: 'client/getAdmins'
		})
	},
	methods: {
		...mapActions({
			fetchAdmin: 'client/fetchAdmin'
		}),
		onLogInfo(admin) {
			console.log('admin', admin)
		},
		async onHandleFetchAdmin(vendor) {
			await this.fetchAdmin({
				dataType: 'user-profile',
				role: 'admin',
				vendorId: vendor.id
			})
		}
	},
	watch: {
		vendor: {
			async handler(value) {
				if (value && value.id) this.onHandleFetchAdmin(value)
			},
			immediate: true
		}
	}
}
</script>
