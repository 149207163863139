<template>
	<DomainDetail />
</template>
<script>
import DomainDetail from '@/modules/DomainDetail/index.vue'
export default {
	components: {
		DomainDetail
	}
}
</script>
