<template>
	<BaseWrapper
		title="Cấu hình DRM whitelist"
		buttonText="Lưu"
		@click="canWatchData = true"
		@onClick="onSave"
		:disabled="!isDataChange"
	>
		<v-switch v-model="isVendorDRMAllowed" :label="configLabelText" />
	</BaseWrapper>
</template>
<script>
import BaseWrapper from '@/templates/wrapper/BaseWrapper'
import {mapGetters, mapActions} from 'vuex'
import get from 'lodash-es/get'
export default {
	components: {
		BaseWrapper
	},
	computed: {
		...mapGetters({
			vendor: 'vendor/getVendor'
		}),
		configLabelText() {
			return this.isVendorDRMAllowed
				? 'Cho phép video được encode với key bảo mật'
				: 'Không cho phép video được encode với key bảo mật'
		}
	},
	data() {
		return {
			isVendorDRMAllowed: false,
			isDataChange: false,
			canWatchData: false
		}
	},
	methods: {
		...mapActions({
			updateVendor: 'vendor/updateVendor'
		}),
		async onSave() {
			try {
        await this.$utils.delay(200)
				await this.updateVendor({
					id: this.vendor.id,
					isVendorDRMAllowed: this.isVendorDRMAllowed
				})
				this.$message.success('Lưu cấu hình thành công')
			} catch (error) {
				this.$message.error(error)
			}
		}
	},
	watch: {
		vendor: {
			handler(val) {
				if (val) {
					this.isVendorDRMAllowed = get(val, 'isVendorDRMAllowed', false)
				}
			},
			immediate: true,
			deep: true
		},
		isVendorDRMAllowed: {
			async handler() {
        await this.$utils.delay(200)
				if (this.canWatchData) this.isDataChange = true
			},
			immediate: true
		}
	}
}
</script>
