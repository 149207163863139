<template>
	<div>
		<div class="headline font-weight-bold primary--text mb-4">
			{{ type === 'admin' ? 'Admin' : 'Learn' }} {{ vendor.siteName }}
		</div>
		<v-data-table
			class="has-border"
			disable-sort
			:headers="headers"
			:items="listDomain"
		>
			<template v-slot:[`item.updatedAt`]="{item}">
				{{ moment(item.updatedAt).format('DD/MM/YYYY') }}
			</template>
			<template v-slot:[`item.isWhitelist`]="{item}">
				<div
					v-if="item.isWhitelist"
					class="d-flex justify-center align-center green--text"
				>
					<v-icon color="green" class="mr-2">mdi-check-circle</v-icon>
					Đã gán
				</div>
			</template>
			<template v-slot:[`item.actions`]="{item}">
				<div class="d-flex justify-end">
					<v-btn icon color="primary" @click="() => onUpdate(item)">
						<v-icon small>mdi-pencil-outline</v-icon>
					</v-btn>
				</div>
			</template>
		</v-data-table>
	</div>
</template>
<script>
import moment from 'moment'
import {mapGetters, mapMutations} from 'vuex'
import get from 'lodash-es/get'
export default {
	props: {
		vendor: Object,
		type: {
			type: String,
			default: () => 'admin'
		}
	},
	data() {
		return {
			moment,
			headers: [
				{text: 'Tên miền', value: 'domain', width: '20%'},
				{text: 'Trạng thái', value: 'status', width: '20%', align: 'center'},
				{
					text: 'Cập nhật lúc',
					value: 'updatedAt',
					width: '20%',
					align: 'center'
				},
				{
					text: 'DRM Whitelist',
					value: 'isWhitelist',
					width: '20%',
					align: 'center'
				},
				{text: 'Thao tác', value: 'actions', width: '20%', align: 'end'}
			]
		}
	},
	computed: {
		...mapGetters({
			domains: 'domain/getDomains'
		}),
		listDomain() {
			if (!this.vendor || !this.vendor.hostnames) return []
			return this.vendor[`${this.type}`].hostnames.map(hostname => {
				return {
					domain: hostname,
					updatedAt: this.vendor.updatedAt,
					status: this.vendor.registrationState,
					isWhitelist: this.getDomainDRMAllowed(hostname)
				}
			})
		}
	},
	methods: {
		...mapMutations({
			setOnUpdateDomain: 'domain/setDomain'
		}),
		getDomainDRMAllowed(domain) {
			if (Array.isArray(this.domains) && this.domains.length) {
				const existedDomain = this.domains.find(x => x.domain === domain)
				if (existedDomain) return get(existedDomain, 'isWhitelist', false)
			}
			return false
		},
		onUpdate(item) {
			this.setOnUpdateDomain(this.domains.find(x => x.domain === item.domain))
			this.$emit('onUpdateDomain')
		}
	}
}
</script>
