<template>
	<BasePageLayout>
		<div slot="page__title">
			<div class="d-flex items-center">
				{{ vendor.learn.hostname }}
				<v-icon v-if="vendor.status === 'locked'" class="ml-4" color="red"
					>mdi-lock</v-icon
				>
			</div>
		</div>
		<div slot="page__actions">
			<BaseButton
				color="gray"
				text="Quay lại"
				icon="mdi-arrow-left"
				@click="$router.push('/domain')"
			/>
		</div>
		<div class="pa-8">
			<v-tabs
				v-model="tab"
				show-arrows
				slider-color="primary"
				active-class="active-tab"
			>
				<v-tab ripple>Thông tin chung</v-tab>
				<v-tab ripple>Cấu hình domain</v-tab>
				<v-tab ripple>Kiểm tra domain</v-tab>
				<v-tab ripple>Quản lý tài khoản admin</v-tab>
				<v-tab ripple>Cấu hình gói</v-tab>
				<v-tab ripple>Xoá/khóa tài khoản</v-tab>
				<v-tab-item class="pa-8">
					<DomainDrmInfo />
					<DomainInfo
						class="mb-4"
						:vendor="vendor"
						type="admin"
						@onUpdateDomain="() => $refs['update-domain-dialog'].openDialog()"
					/>
					<DomainInfo
						class="mb-4"
						:vendor="vendor"
						type="learn"
						@onUpdateDomain="() => $refs['update-domain-dialog'].openDialog()"
					/>
				</v-tab-item>
				<v-tab-item class="pa-8">
					<SettingsDomain
						ref="settings-domain"
						@addCustomDomain="domain => addDomain(domain)"
					/>
				</v-tab-item>
				<v-tab-item>
					<DomainCheck />
				</v-tab-item>
				<v-tab-item class="pa-8">
					<AdminAccountManagement :vendor="vendor" />
				</v-tab-item>
				<v-tab-item class="pa-8">
					<UsingPackage />
				</v-tab-item>
				<v-tab-item class="pa-8">
					<lock-client />
					<v-divider />
					<DeleteClient @delete="data => onDelete(data)" />
				</v-tab-item>
			</v-tabs>
		</div>
		<DomainInfoDialog ref="update-domain-dialog" @confirm="onUpdateDomain" />
	</BasePageLayout>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import AdminAccountManagement from '@/modules/AdminAccount/index.vue'
import DeleteClient from '@/modules/ClientDetail/components/DeleteClient'
import SettingsDomain from '@/modules/DomainDetail/components/SettingsDomain'
import DomainInfo from '@/modules/Domain/components/DomainInfo'
import DomainDrmInfo from '@/modules/Domain/components/DomainDrmInfo'
import DomainInfoDialog from '@/modules/Domain/components/DomainInfoDialog'
import BasePageLayout from '@/templates/layout/BasePageLayout'
import UsingPackage from '@/modules/UsingPackage/index.vue'
import LockClient from '../ClientDetail/components/LockClient.vue'
import DomainCheck from '@/modules/Domain/components/DomainCheck'
export default {
	components: {
		BasePageLayout,
		DomainInfo,
		SettingsDomain,
		DeleteClient,
		AdminAccountManagement,
		DomainDrmInfo,
		DomainInfoDialog,
		UsingPackage,
		LockClient,
		DomainCheck
	},
	async mounted() {
		this.$message.loading('Đang lấy dữ liệu...', 10000)
		await this.getDataFromApi()
		this.$message.close()
	},
	data() {
		return {
			tab: 0
		}
	},
	computed: {
		...mapGetters({
			vendor: 'vendor/getVendor',
			domain: 'domain/getDomain'
		})
	},
	methods: {
		...mapActions({
			fetchVendorById: 'vendor/fetchVendorById',
			addCustomDomain: 'vendor/addCustomDomain',
			removeVendor: 'vendor/removeVendor',
			fetchDomains: 'domain/fetchDomains',
			updateDomain: 'domain/updateDomain'
		}),
		async onDelete(data) {
			if (data === 'izteach-confirm-delete') {
				try {
					this.$message.loading('Đang xoá dữ liệu ...', 10000)
					await this.removeVendor(this.vendor.id)
					this.$message.success('Xoá dữ liệu thành công !', 3000)
					this.$router.push('/domain')
				} catch (error) {
					this.$message.error('Đã có lỗi xảy ra')
				}
			} else this.$message.error('Mã xác nhận không đúng !')
		},
		async addDomain(domain) {
			try {
				this.$message.loading('Đang cập nhật dữ liệu...', 10000)
				await this.addCustomDomain({
					id: this.vendor.id,
					body: {
						domain: domain
					}
				})
				this.$message.success('Thêm tên miền thành công !', 3000)
				this.$refs['settings-domain'].closeDialog()
				await this.getDataFromApi()
				this.tab = 0
			} catch (error) {
				this.$message.error(error)
			}
		},
		async getDataFromApi() {
			await this.fetchVendorById(this.$route.params.id)
			await this.fetchDomains({vendorId: this.$route.params.id})
		},
		async onUpdateDomain(isWhitelist) {
			try {
				this.$message.loading('Đang cập nhật dữ liệu...', 10000)
				await this.updateDomain({id: this.domain.id, isWhitelist})
				this.$message.success('Cập nhật tên miền thành công !', 3000)
				this.$refs['update-domain-dialog'].closeDialog()
				await this.getDataFromApi()
			} catch (error) {
				this.$message.error(error)
			}
		}
	}
}
</script>
