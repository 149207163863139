<template>
	<div class="mb-12">
		<div class="headline font-weight-bold mb-4">{{ textAction }} tài khoản</div>
		<BaseButton
			icon="mdi-lock"
			@click="dialog = true"
			color="primary"
			:text="`Xác nhận ${textAction}`"
		/>
		<v-dialog v-model="dialog" persistent max-width="600px">
			<v-card class="pa-2">
				<v-card-title class="d-flex">
					<span class="headline font-weight-bold"
						>{{ textAction }} khách hàng này ?</span
					>
				</v-card-title>
				<v-card-text>
					<div>
						Bạn có thực sự chắc chắn muốn {{ textAction }} khách hàng này ?.
					</div>
					<BaseInput
						class="mt-4"
						v-model="confirmName"
						placeholder="Nhập dòng chữ bên dưới để xác nhận"
					/>
					<div class="mb-2">IZTeach Confirm</div>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="darken-1" text @click="dialog = false">
						Huỷ
					</v-btn>
					<v-btn color="red" text @click="confirmLock">
						{{ textAction }} tài khoản
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
export default {
	data() {
		return {
			dialog: false,
			confirmName: ''
		}
	},
	computed: {
		...mapGetters({
			vendor: 'vendor/getVendor'
		}),
		isLocked() {
			return this.vendor.status === 'locked'
		},
		textAction() {
			return this.isLocked ? 'Mở khóa' : 'Khóa'
		}
	},
	methods: {
		...mapActions({
			updateVendor: 'vendor/updateVendor'
		}),
		async confirmLock() {
			const input = this.$utils.clearUnicode(this.confirmName)
			if (input === 'izteach-confirm') {
				try {
					this.$message.loading('Đang cập nhật dữ liệu ...', 10000)
					await this.updateVendor({
						id: this.$route.params.id,
						status: this.isLocked ? 'success' : 'locked'
					})
					this.$message.success(`${this.textAction} trang thành công !`, 3000)
					this.dialog = false
				} catch (error) {
					this.$message.error('Đã có lỗi xảy ra')
					this.dialog = false
				}
			} else this.$message.error('Mã xác nhận không đúng !')
		}
	}
}
</script>
