<template>
	<v-dialog persistent v-model="dialog" width="50%">
		<v-card>
			<v-card-title class="pa-6 d-flex justify-space-between align-center">
				Cập nhật tên miền
				<v-icon @click="closeDialog">mdi-close</v-icon>
			</v-card-title>
			<v-divider />
			<v-card-text class="pa-6">
				<div>
					Tên miền cần cập nhật:
					<span class="black--text font-weight-bold">{{
						get(curDomain, 'domain', '')
					}}</span>
				</div>
				<v-switch v-model="isWhitelist" :label="configLabelText" hide-details />
			</v-card-text>
			<v-divider />
			<v-card-actions class="pa-6">
				<BaseButton text="Hủy" @click="closeDialog" outlined />
				<v-spacer />
				<BaseButton text="Xác nhận" @click="onConfirm" />
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import {mapGetters} from 'vuex'
import get from 'lodash-es/get'
export default {
	data() {
		return {
			dialog: false,
			isWhitelist: false,
			get
		}
	},
	computed: {
		...mapGetters({
			curDomain: 'domain/getDomain'
		}),
		configLabelText() {
			return this.isWhitelist
				? 'Gắn tên miền vào DRM Whitelist'
				: 'Gỡ tên miền khỏi DRM Whitelist'
		}
	},
	methods: {
		openDialog() {
			this.dialog = true
		},
		closeDialog() {
			this.dialog = false
		},
		refresh() {
			this.isWhitelist = false
		},
		onConfirm() {
			this.$emit('onConfirm', this.isWhitelist)
		}
	},
	watch: {
		curDomain: {
			handler(val) {
				this.isWhitelist = get(val, 'isWhitelist', false)
			},
			immediate: true,
			deep: true
		}
	}
}
</script>
