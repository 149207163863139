<template>
	<div>
		<div>
			Trước khi thêm tên miền phụ, hãy vào trang quản lý tên miền và cài đặt như
			sau
		</div>
		<div>
			Trỏ địa chỉ <strong>75.2.60.5</strong> như hình bên dưới và lưu lại
		</div>
		<img style="width: 100%" src="/admin-static/paaccess.png" />
		<BaseButton @click="openDialog" text="Bạn muốn thêm tên miền phụ ?" />
		<v-dialog v-model="dialog" max-width="600px">
			<v-card class="pa-6">
				<v-card-title class="d-flex justify-center mb-4">
					<div class="headline mb-2">Thêm tên miền</div>
					<div class="caption">
						Tên miền được thêm vào bao gồm <strong>"tên miền chính"</strong> và
						tên miền
						<strong>admin."tên miền chính"</strong>
					</div>
					<div class="caption text-center">
						Bạn cần đảm bảo tên miền
						<strong>đã trỏ về server của izteach</strong> và sau khi thêm thì hệ
						thống sẽ tự động tạo bảo mật ssl sau 5 phút <br />
					</div>
				</v-card-title>
				<v-card-text>
					<v-form ref="form">
						<BaseInput
							:rules="[$rules.required, $rules.domain]"
							v-model="domain"
							placeholder="Nhập tên miền muốn thêm VD: tuduymo.com"
						/>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<BaseButton
						@click="addCustomDomain"
						class="mr-2"
						color="green"
						text="Thêm tên miền mới"
						icon="mdi-plus"
					/>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
export default {
	data() {
		return {
			dialog: false,
			domain: ''
		}
	},
	methods: {
		openDialog() {
			this.dialog = true
		},
		closeDialog() {
			this.dialog = false
			this.domain = ''
		},
		addCustomDomain() {
			if (!this.$refs.form.validate()) return
			this.$emit('addCustomDomain', this.domain)
		}
	}
}
</script>
