<template>
	<v-form ref="form">
		<v-text-field
			dense
			ref="name"
			placeholder="Nhập họ và tên"
			:rules="[$rules.required]"
			v-model.trim="form.name"
			@keyup.native.enter="signUp"
			outlined
		></v-text-field>
		<v-text-field
			ref="email"
			dense
			placeholder="Nhập email"
			:rules="[$rules.required, $rules.email]"
			v-model.trim="form.email"
			@keyup.native.enter="signUp"
			outlined
		></v-text-field>
		<v-text-field
			ref="phone"
			dense
			placeholder="Nhập số điện thoại"
			:rules="[$rules.required, $rules.phone]"
			v-model.trim="form.phone"
			@keyup.native.enter="signUp"
			outlined
		></v-text-field>
		<v-text-field
			dense
			:type="showPass ? 'text' : 'password'"
			:append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
			hint="Mậu khẩu chứa ít nhất 6 ký tự"
			:rules="[$rules.required, $rules.minLength(6)]"
			@click:append="showPass = !showPass"
			v-model.trim="form.password"
			placeholder="Nhập mật khẩu"
			@keyup.native.enter="signUp"
			ref="password"
			outlined
		></v-text-field>
		<v-text-field
			dense
			:type="showReCheckPass ? 'text' : 'password'"
			:append-icon="showReCheckPass ? 'mdi-eye' : 'mdi-eye-off'"
			hint="Mậu khẩu chứa ít nhất 6 ký tự"
			:rules="[
				$rules.required,
				$rules.minLength(6),
				$rules.confirmPassword(form.password, form.reCheckPassword)
			]"
			@click:append="showReCheckPass = !showReCheckPass"
			v-model.trim="form.reCheckPassword"
			placeholder="Nhập lại mật khẩu"
			@keyup.native.enter="signUp"
			ref="password"
			outlined
		></v-text-field>
		<v-text-field
			outlined
			dense
			label="Mã xác nhận"
			:rules="[$rules.required, captchaValidate]"
			v-model.trim="form.captcha"
		/>
		<div class="pb-2 d-flex justify-space-between align-center">
			<BaseCaptcha ref="captcha" />
			<div>
				<BaseButton
					class="mr-3"
					color="gray"
					text="Đóng"
					@click="$emit('closeDialog')"
				/>
				<BaseButton class="mr-3" text="Xác nhận" @click="signUp" />
			</div>
		</div>
	</v-form>
</template>
<script>
export default {
	data() {
		return {
			form: {
				name: '',
				email: '',
				phone: '',
				password: '',
				reCheckPassword: '',
				captcha: ''
			},
			showPass: false,
			showReCheckPass: false
		}
	},
	methods: {
		captchaValidate(value) {
			if (this.$refs.captcha) {
				return this.$refs.captcha.validate(value) || 'Nhập lại mã xác thưc'
			}
			return false
		},
		signUp() {
			if (!this.$refs.form.validate()) return
			else this.$emit('register', this.form)
		}
	}
}
</script>
