<template>
	<v-dialog v-model="dialog" max-width="600px">
		<v-card class="pa-6">
			<v-card-title class="d-flex justify-center">
				<span class="headline">Tạo admin mới</span>
			</v-card-title>
			<v-card-text>
				<FormRegisterAccount
					@closeDialog="closeDialog"
					@register="data => register(data)"
				/>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
import {mapActions} from 'vuex'
import FormRegisterAccount from '@/modules/Clients/components/FormRegisterAccount'
import Base64 from '@/helpers/encodeBase64.js'
export default {
	props: {
		vendor: Object
	},
	components: {
		FormRegisterAccount
	},
	data() {
		return {
			dialog: false
		}
	},
	methods: {
		...mapActions({
			createNewAdmin: 'client/createNewAdmin'
		}),
		openDialog() {
			this.dialog = true
		},
		closeDialog() {
			this.dialog = false
		},
		async register(data) {
			delete data['captcha']
			delete data['reCheckPassword']
			try {
				this.$message.loading('Đang đăng kí tài khoản...', 20000)
				await this.createNewAdmin({
					...data,
					vendorId: this.vendor.id,
					roles: ['admin'],
					state: 'verified',
					password: Base64.encode(data.password),
					secretKey: 's3Cr3tK3y'
				})
				this.closeDialog()
				this.$emit('fetchAdmins')
				this.$message.success('Tạo tài khoản thành công !')
			} catch (error) {
				this.$message.error('Có lỗi xảy ra !')
			}
		}
	}
}
</script>
